@font-face {
  font-family: "KnubiRegular";
  src: local("KnubiRegular"),
    url("./fonts/Knubi-Regular_ik.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Mulish', sans-serif;
}

* {
  font-family: 'Mulish', sans-serif;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}