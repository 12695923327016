.aboutMe-text__container {
  display: inline-block;
  padding: 20px;
  /* border-style: solid;
  border-color: #254666;
  border-width: 2px; */
  flex: 1;
}

.pacific-blue {
  color: #254666;
}

.pacific-blue-light {
  color: #667e94;
}

.pacific-blue-dark {
  color: #132333;
}

.silver {
  color: #EBEBE3;
}

.rose-gold {
  display: inline-block;
  padding: 7px;
  background-color: #FAD7BD;
  color: #254666;
  border-radius: 7px;
}

/* 
.aboutMe-text__container {
    flex: 1;  
    @media all and (min-width: 720px) {
      margin: 0 30px;
      padding: 20px;
      border: 1px solid $white; 
    }
  } */


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #EBEBE3 !important;
  background-color: #254666 !important;
}

.nav-link {
  color: #254666 !important;
  border-bottom: 1px solid #254666 !important;
  border-radius: 0px !important;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.nav-link:hover {
  background-color: #FAD7BD !important;
}

.card {
  background-color: #EBEBE3 !important;
  border: 0px !important;
}

.card-body {
  padding-left: 0px !important;
}

.shadoww {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.welcome {
  background: linear-gradient(rgba(17, 15, 15, 0.3), rgba(37, 34, 34, 0.7)), url("../images/background.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
}

@media (min-width: 767px) { 
  .fontChange { 
    font-size: 25px; 
  } 
  .nameSize {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .project {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

}

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  position: absolute;
  overflow: hidden;
  display: table;
  width: 90%;
  height: 80%;
  left: 5%;
  top: 10%;
  border-bottom: 1px solid #EBEBE3;
  border-top: 1px solid #EBEBE3;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0,1);
  -ms-transform: scale(0,1);
  transform: scale(0,1);
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.hovereffect img {
  display: block;
  position: relative;
  height: 100%;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.hovereffect .tbCell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.hovereffect:hover img {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.6" /><feFuncG type="linear" slope="0.6" /><feFuncB type="linear" slope="0.6" /></feComponentTransfer></filter></svg>#filter');
  filter: brightness(0.35);
  -webkit-filter: brightness(0.35);
}

.hovereffect h2 {
  position: relative;
  font-size: 18px;
  background-color: transparent;
  color: #EBEBE3;
  margin: 0;
  padding: 5px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}

.hovereffect p {
  color: #EBEBE3;
  margin: 0;
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}

.hovereffect:hover p, .hovereffect:hover h2 {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.icon-line {
  height: 100px;
  margin-right: 8px !important;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.resume-line {
  height: 100px;
  margin-left: 12px !important;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.container-link-top {
  position: absolute;
  z-index: 999;
  right: 1%;
  top: 0;
  pointer-events: none;
}

.container-link-bottom {
  position: absolute;
  z-index: 999;
  left: 1%;
  bottom: 0;
}

.icon {
  padding-top: 20px;
}

.icon svg:hover {
  opacity: 0.5;
  transform: scale(1.5);
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

.vertical-text {
  transform: rotate(-90deg);
  transform-origin: left top;
  /* padding-bottom: 10px; */
}

.vertical-text:hover {
  transform: rotate(-90deg) scale(1.2);
  transform-origin: left top;
  opacity: 0.5;
  /* color: #1F2020; */
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

a {
  color: inherit !important; /* blue colors for links too */
  text-decoration: inherit !important; /* no underline */
  pointer-events: auto;
}